<template lang="pug">
v-dialog(
  v-model="isOpen"
  content-class="sort-popup"
  transition="dialog-bottom-transition"
  width="100%"
)
  v-card
    v-card-title.px-4.py-3
      .t-h3-mobile.mr-auto Сортировка
      ui-icon.c-black70(name="close" size="24" @click="close")
    v-card-text.px-4.pt-0.pb-10
      v-list.py-0
        v-list-item-group
          template(v-for="sort in sorts")
            v-list-item.px-0(
              :key="sort.value"
              @click="sort.value != currentSort ? changeSort(sort) : close()"
            )
              v-list-item-title.t-normal.c-black80.px-0 {{ sort.text }}
              v-list-item-icon.my-auto(v-if="sort.value == currentSort")
                ui-icon.c-primary(name="check" size="18")
            v-divider
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    name: 'sort-popup',
    components: { UiBtn, UiIcon },
    data() {
      return {
        isOpen: false,
        currentSort: null,
        sorts: [
          { value: 'priority_desc', text: 'По умолчанию' },
          { value: 'created_at_desc', text: 'Сначала новые' },
          { value: 'formatted_price_asc', text: 'Стоимость по возрастанию' },
          { value: 'formatted_price_desc', text: 'Стоимость по убыванию' },
          { value: 'views_count_desc', text: 'Популярные за месяц' },
        ],
      };
    },

    methods: {
      openModal(currentSort) {
        if (!this.currentSort) {
          this.currentSort = currentSort.value;
        }
        this.isOpen = true;
      },

      changeSort(sort) {
        this.currentSort = sort.value;
        this.$emit('change', sort);
        this.close();
      },

      close() {
        this.isOpen = false;
      },
    },
  };
</script>

<style lang="scss">
  .sort-popup {
    &.v-dialog {
      margin: auto 0 0;

      .v-card {
        .v-list-item--active {
          color: transparent;
        }
        .v-list-item {
          min-height: 44px;
          height: 44px;
        }
      }
    }
  }
</style>
